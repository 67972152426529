import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coordinates: {
    latitude: 40.7817,
    longitude: -73.96648,
  },
  city: 'New York',
  state: 'NY',
  zipCode: '10024',
  user: null,
  selectedSeller: null,
  creatingUser: false,
  uuid: null,
  loggingIn: false,
  applyToSell: false,
  lastSeenRoomTimes: {}, // { [roomId]: number (milliseconds) }
  rooms: [],
  unreadMessages: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSelectedSeller: (state, action) => {
      state.selectedSeller = action.payload;
    },
    setCreatingUser: (state, action) => {
      state.creatingUser = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setLoggingIn: (state, action) => {
      state.loggingIn = action.payload;
    },
    setApplyToSell: (state, action) => {
      state.applyToSell = action.payload;
    },
    setLastSeenRoomTimes: (state, action) => {
      const { roomId, timestamp } = action.payload;

      // Ensure the lastSeenRoomTimes object exists before setting
      if (!state.lastSeenRoomTimes) {
        state.lastSeenRoomTimes = {};
      }

      // Store timestamp as milliseconds since epoch
      state.lastSeenRoomTimes[roomId] = timestamp;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
    updateAvatarUrl: (state, action) => {
      if (state.user) {
        state.user.avatar_url = action.payload;
      }
    },
    resetAuthState: () => initialState,
  },
});

export const {
  setCoordinates,
  setCity,
  setState,
  setZipCode,
  setUser,
  setSelectedSeller,
  setCreatingUser,
  setUuid,
  setLoggingIn,
  setApplyToSell,
  resetAuthState,
  setLastSeenRoomTimes,
  setRooms,
  setUnreadMessages,
  updateAvatarUrl,
} = userSlice.actions;

export default userSlice.reducer;
