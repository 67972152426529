import React, { useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import { ButtonGeneral } from '../components/buttonGeneral';
import { useSelector } from 'react-redux';
import './styles/CustomModal.css';

export const CustomModal = ({
  component,
  shown,
  imageComponent,
  close,
  text,
  buttonText,
  onPress,
  color,
  buttonTextColor,
  buttonL,
  buttonLText,
  buttonLColor,
  buttonLTextColor,
  buttonLHandlePress,
}) => {
  const { theme } = useContext(PreferencesContext);
  const seller = useSelector((state) => state.seller.seller);

  if (!shown) return null;

  return (
    <div
      className='modal-background'
      onClick={close}>
      <div
        className='modal-content'
        onClick={(e) => {
          e.stopPropagation(); // Prevents the modal from closing when clicking inside
        }}>
        {imageComponent}
        {text && <p className='modal-text'>{text}</p>}
        {component}
        <div className={buttonL ? 'button-row' : 'button-single'}>
          {buttonL && (
            <ButtonGeneral
              title={buttonLText}
              titleColor={buttonLTextColor || theme.colors.onBackground}
              fontFamily='Semi'
              fontSize={14}
              color={
                buttonLColor ? buttonLColor : seller ? theme.colors.pieGreen : theme.colors.pieBlue
              }
              onPress={buttonLHandlePress}
              margin={15}
              flex
            />
          )}
          <ButtonGeneral
            title={buttonText}
            titleColor={buttonTextColor || theme.colors.onBackground}
            fontFamily='Semi'
            fontSize={14}
            color={color ? color : seller ? theme.colors.pieGreen : theme.colors.pieBlue}
            onPress={onPress}
            margin={15}
            flex={buttonL ? 1 : null}
          />
        </div>
      </div>
    </div>
  );
};
