import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { setSeller, resetAuthState, setUser } from './redux/features/sellerSlice';
import LandingPage from './screens/LandingPage';
import Login from './screens/Login';
import ChoosePOS from './screens/ChoosePOS';
import Inventory from './screens/Inventory';
import TermsConditions from './screens/TermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactUs from './screens/ContactUs';
import AllSet from './screens/AllSet';
import AuthRedirect from './screens/AuthRedirect';
import Settings from './screens/Settings';
import CreateAccount from './screens/CreateAccount';
import SellOnPie from './screens/SellOnPie';
import DeleteAccount from './screens/DeleteAccount';
import './App.css';

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const seller = useSelector((state) => state.seller.seller);
  const user = useSelector((state) => state.seller.user);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
  //     setLoading(true);

  //     if (firebaseUser) {
  //       try {
  //         dispatch(setUser(firebaseUser.uid));
  //       } catch (error) {
  //         console.error('Error fetching seller info:', error);
  //         dispatch(resetAuthState());
  //       }
  //     } else {
  //       dispatch(setUser(null));
  //       dispatch(resetAuthState());
  //     }

  //     setLoading(false);
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, [dispatch]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Router>
      <div className='App'>
        {/* Routing Setup */}
        <Routes>
          {/* Redirect Route */}
          <Route
            path='/redirect'
            element={<AuthRedirect />}
          />

          {/* Public Routes */}
          <Route
            path='/'
            element={<LandingPage />}
          />
          <Route
            path='/login'
            element={<Login />}
          />
          <Route
            path='/apply'
            element={<SellOnPie />}
          />
          <Route
            path='/sign-up'
            element={<CreateAccount />}
          />
          <Route
            path='/terms'
            element={<TermsConditions />}
          />
          <Route
            path='/privacy'
            element={<PrivacyPolicy />}
          />
          <Route
            path='/contact'
            element={<ContactUs />}
          />
          <Route
            path='/all-set'
            element={<AllSet />}
          />
          <Route
            path='/delete-account'
            element={<DeleteAccount />}
          />

          {/* Protected Routes */}
          <Route
            path='/inventory'
            element={user && seller && <Inventory />}
          />
          <Route
            path='/choose-pos'
            element={user && seller && <ChoosePOS />}
          />
          <Route
            path='/settings'
            element={
              seller ? (
                <Settings />
              ) : (
                <Navigate
                  to='/login'
                  replace
                />
              )
            }
          />

          {/* Catch-all Route */}
          <Route
            path='*'
            element={
              <Navigate
                to='/redirect'
                replace
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
