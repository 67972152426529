import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { createUser } from '../api/authApi';
// import { setUser, setApplyToSell } from '@features/userSlice';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { Toast_ } from '../components/toast';
import { InputGeneral } from '../components/inputGeneral';
import { ButtonGeneral } from '../components/buttonGeneral';
// import { GoBack } from '@components/goBack';
import { PieIcons } from '../components/icons/PIE_Icons';
import { PreferencesContext } from '../context/theme';
import { useNavigate } from 'react-router-dom';
import logo from '../PIELogo.png';
import './styles/CreateAccount.css';

const CreateAccount = () => {
  const { theme } = useContext(PreferencesContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellOnPie, setSellOnPie] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showPass = () => {
    setVisible(!visible);
  };

  const validationSchema = yup.object().shape({
    displayName: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ '.&!]{1,50}$/,
        'Display Name must be 1-50 characters long and can include alphabets, numbers, spaces, hyphens, underscores, apostrophes, periods, ampersands, and exclamation marks.',
      )
      .required('Display Name is Required'),
    email: yup.string().email('Please enter a valid email').required('Email Address is Required'),
    password: yup
      .string()
      .matches(/\w*[a-z]\w*/, 'Password must contain at least one lowercase letter.')
      .matches(/\w*[A-Z]\w*/, 'Password must contain at least one uppercase letter.')
      .matches(/\d/, 'Password must contain at least one number.')
      .matches(
        /[!@#$%^&*()\-_"=+{}; :,<.>]/,
        'Password must contain at least one special character (e.g., !@#$%^&*).',
      )
      .min(8, ({ min }) => `Password must be at least ${min} characters long.`)
      .required('Password is required.'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required.'),
    terms_and_privacy: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and privacy policy')
      .required('Terms and Privacy acceptance is required'),
  });

  const handleSignUp = async (values) => {
    setLoading(true);
    try {
      await validationSchema.validate(values, { abortEarly: false });

      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        values.email.trim(),
        values.password,
      );

      const { uid, email } = userCredentials.user;

      // Create a user document in Firestore
      const userDocRef = doc(db, 'users', uid);
      await setDoc(userDocRef, {
        userId: uid,
        createdAt: serverTimestamp(),
        username: values.displayName.trim(),
        email: values.email,
      });

      const user = await createUser({
        uid,
        displayName: values.displayName.trim(),
        email,
      });

      //   dispatch(setUser(user));
      //   dispatch(setApplyToSell(sellOnPie));

      //   navigate(sellOnPie ? '/SellOnPie' : '/UserTabs');

      setLoading(false);
    } catch (error) {
      let message = 'An unexpected error occurred.';
      if (error.code) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            message = 'The email address is already in use.';
            break;
          case 'auth/invalid-email':
            message = 'The email address is not valid.';
            break;
          case 'auth/operation-not-allowed':
            message = 'Email/password accounts are not enabled.';
            break;
          case 'auth/weak-password':
            message = 'The password is too weak.';
            break;
          default:
            message = error.message || 'An unexpected error occurred.';
        }
      } else if (error.inner && error.inner.length > 0) {
        // Handle Formik validation errors
        message = error.inner.map((err) => err.message).join('\n');
      } else {
        message = error.message || 'An unexpected error occurred.';
      }

      Toast_({
        text1: 'Sign Up Failed',
        text2: message,
        type: 'error',
        position: 'top',
        topOffset: 60,
      });

      console.error('Sign Up Error', message);

      setLoading(false);
    }
  };

  return (
    <div className='create-account-container'>
      <div className='scroll-view'>
        <Formik
          initialValues={{
            displayName: '',
            email: '',
            password: '',
            confirmPassword: '',
            terms_and_privacy: false,
            apply_to_sell: false,
          }}
          onSubmit={handleSignUp}
          validationSchema={validationSchema}>
          {({
            handleBlur,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isValid,
            dirty,
          }) => (
            <form
              onSubmit={handleSubmit}
              className='form-container'>
              <h1 className='title'>
                Let's Get <span className='secondary-word'>Started</span>
              </h1>
              <InputGeneral
                placeholder='Display Name'
                label='Display Name'
                value={values.displayName}
                onChange={(e) => setFieldValue('displayName', e.target.value)}
                onBlur={handleBlur('displayName')}
                error={errors.displayName && touched.displayName ? errors.displayName : undefined}
              />

              <InputGeneral
                placeholder='Email'
                label='Email'
                value={values.email}
                type='email'
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={handleBlur('email')}
                error={errors.email && touched.email ? errors.email : undefined}
              />

              <InputGeneral
                placeholder='Password'
                label='Password'
                value={values.password}
                onChange={(e) => setFieldValue('password', e.target.value)}
                onBlur={handleBlur('password')}
                error={errors.password && touched.password ? errors.password : undefined}
                type={visible ? 'text' : 'password'}
                iconR={
                  <button
                    type='button'
                    onClick={showPass}
                    className='password-toggle-button'>
                    <PieIcons
                      name='eye'
                      size={24}
                      color={theme.colors.disabled}
                    />
                  </button>
                }
              />

              <InputGeneral
                placeholder='Confirm Password'
                label='Confirm Password'
                value={values.confirmPassword}
                onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                onBlur={handleBlur('confirmPassword')}
                error={
                  errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : undefined
                }
                type={visible ? 'text' : 'password'}
                iconR={
                  <button
                    type='button'
                    onClick={showPass}
                    className='password-toggle-button'>
                    <PieIcons
                      name='eye'
                      size={24}
                      color={theme.colors.disabled}
                    />
                  </button>
                }
              />

              <div className='checkbox-container'>
                <input
                  type='checkbox'
                  id='terms_and_privacy'
                  checked={values.terms_and_privacy}
                  onChange={(e) => setFieldValue('terms_and_privacy', e.target.checked)}
                  className='checkbox'
                />
                <label
                  htmlFor='terms_and_privacy'
                  className='terms-text'>
                  I agree to Shop On PIE's{' '}
                  <a
                    href='https://pienet.net/terms'
                    className='link-text'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://pienet.net/privacy'
                    className='link-text'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>

              <div className='checkbox-container'>
                <input
                  type='checkbox'
                  id='apply_to_sell'
                  checked={values.apply_to_sell}
                  onChange={(e) => {
                    setFieldValue('apply_to_sell', e.target.checked);
                    setSellOnPie(e.target.checked);
                  }}
                  className='checkbox'
                />
                <label
                  htmlFor='apply_to_sell'
                  className='terms-text'>
                  Would you like to{' '}
                  <a
                    href='https://pienet.net/terms'
                    className='link-text'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Sell On PIE
                  </a>
                  ?
                </label>
              </div>

              <ButtonGeneral
                title='Sign Up'
                disabled={!isValid || !dirty}
                onClick={handleSubmit}
                loading={loading}
                width
                margin={10}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateAccount;
