import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  seller: null,
  sellerToken: false,
  listings: [],
  discounts: [],
  locations: [],
  creatingSeller: false,
  firstTimeLogin: false,
  provider: null,
  pendingOrders: false,
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    setSellerToken(state, action) {
      state.sellerToken = action.payload;
    },
    setSellerListings: (state, action) => {
      state.listings = action.payload;
    },
    addListing(state, action) {
      state.listings.push(action.payload);
    },
    editListing(state, action) {
      const index = state.listings.findIndex((listing) => listing.id === action.payload.id);
      if (index !== -1) {
        state.listings[index] = action.payload;
      }
    },
    removeListing(state, action) {
      state.listings = state.listings.filter((listing) => listing.id !== action.payload);
    },
    setSellerLocations: (state, action) => {
      state.locations = action.payload;
    },
    addLocation(state, action) {
      state.locations.push(action.payload);
    },
    editLocation(state, action) {
      const index = state.locations.findIndex((location) => location.id === action.payload.id);
      if (index !== -1) {
        state.locations[index] = action.payload;
      }
    },
    removeLocation(state, action) {
      state.locations = state.locations.filter((location) => location.id !== action.payload);
    },
    upsertLocation(state, action) {
      const updatedLocation = action.payload;
      const index = state.locations.findIndex((listing) => listing.id === updatedLocation.id);

      if (index !== -1) {
        // Update existing listing
        state.locations[index] = updatedLocation;
      } else {
        // Add new listing
        state.locations.push(updatedLocation);
      }
    },
    setSellerDiscounts: (state, action) => {
      state.discounts = action.payload;
    },
    addDiscount(state, action) {
      state.discounts.push(action.payload);
    },
    updateDiscount(state, action) {
      const index = state.discounts.findIndex((discount) => discount.id === action.payload.id);
      if (index !== -1) {
        state.discounts[index] = action.payload;
      }
    },
    removeDiscount(state, action) {
      state.discounts = state.discounts.filter((discount) => discount.id !== action.payload);
    },
    setCreatingSeller: (state, action) => {
      state.creatingSeller = action.payload;
    },
    resetAuthState: () => initialState,
    setFirstTimeLogin: (state, action) => {
      state.firstTimeLogin = action.payload;
    },
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    updateListings: (state, action) => {
      const updatedListings = action.payload;
      updatedListings.forEach((updatedListing) => {
        const index = state.listings.findIndex((listing) => listing.id === updatedListing.id);
        if (index !== -1) {
          state.listings[index] = updatedListing;
        } else {
          state.listings.push(updatedListing);
        }
      });
    },
    setPendingOrders: (state, action) => {
      state.pendingOrders = action.payload;
    },
    updateSellerDescription: (state, action) => {
      if (state.seller) {
        state.seller.seller_description = action.payload;
      }
    },
  },
});

export const {
  setUser,
  setSeller,
  setSellerToken,
  setSellerListings,
  addListing,
  editListing,
  upsertLocation,
  removeListing,
  setSellerLocations,
  addLocation,
  editLocation,
  removeLocation,
  setSellerDiscounts,
  addDiscount,
  updateDiscount,
  removeDiscount,
  setCreatingSeller,
  resetAuthState,
  setFirstTimeLogin,
  setProvider,
  updateListings,
  setPendingOrders,
  updateSellerDescription,
} = sellerSlice.actions;

export default sellerSlice.reducer;
