import React from 'react';
import './styles/DeleteAccount.css';

const DeleteAccount = () => {
  return (
    <div className='delete-account-container'>
      <h1>Delete Your Account</h1>
      <p>
        We’re sorry to see you go. If you need to permanently delete your account, please follow the
        steps below:
      </p>
      <ol>
        <li>
          Open the <strong>Shop On PIE</strong> mobile app on your device.
        </li>
        <li>Log in to your account, if you haven’t already.</li>
        <li>
          Go to the <strong>Settings</strong> screen in the app.
        </li>
        <li>
          Select the option to <strong>Delete Account</strong>.
        </li>
        <li>Follow any prompts to confirm your account deletion.</li>
      </ol>
      <p className='delete-account-note'>
        Once your account is deleted, all associated data will be permanently removed. If you change
        your mind later, you’ll need to create a new account.
      </p>
      <p className='delete-account-note'>
        If you have any questions or encounter issues, please contact our support team at
        info@pienet.net.
      </p>
    </div>
  );
};

export default DeleteAccount;
