import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/Toast.css'; // Custom styles
import PropTypes from 'prop-types';

/**
 * Toast_ is a utility function to display toast notifications.
 *
 * @param {Object} options - The options for the toast.
 * @param {string} options.text1 - The main text of the toast.
 * @param {string} [options.text2] - The secondary text of the toast.
 * @param {string} options.type - The type of toast ('success', 'error', etc.).
 * @param {string} [options.position] - The position of the toast.
 * @param {number} [options.topOffset] - The top offset for the toast.
 */
export const Toast_ = ({ text1, text2 = null, type, position = 'top-right', topOffset = 50 }) => {
  const options = {
    position: position,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light', // You can change to 'dark' or 'colored'
    className: 'custom-toast',
    style: {
      marginTop: topOffset,
    },
  };

  const renderContent = () => {
    if (text2) {
      return (
        <div>
          <strong>{text1}</strong>
          <div>{text2}</div>
        </div>
      );
    }
    return <div>{text1}</div>;
  };

  switch (type) {
    case 'success':
      toast.success(renderContent(), options);
      break;
    case 'error':
      toast.error(renderContent(), options);
      break;
    case 'info':
      toast.info(renderContent(), options);
      break;
    case 'warning':
      toast.warn(renderContent(), options);
      break;
    default:
      toast(text1, options);
  }
};

/**
 * CustomToastContainer renders the ToastContainer with custom configurations.
 * It should be included once in your app, preferably at the root level.
 */
export const CustomToastContainer = () => (
  <ToastContainer
    position='top-right'
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    transition={Slide}
  />
);

Toast_.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  position: PropTypes.string,
  topOffset: PropTypes.number,
};
