import React, { useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import './styles/ButtonGeneral.css';

export const ButtonGeneral = ({
  onPress,
  title,
  titleColor,
  disabled,
  loading,
  color,
  borderColor,
  border = false,
  radius = 25,
  fontSize = 16,
  fontFamily = 'Montserrat',
  fontWeight = 600,
  icon,
  margin,
  flex,
  height = 50,
  width = false,
  circular = false,
}) => {
  const { theme } = useContext(PreferencesContext);

  const buttonStyle = {
    height: `${height}px`,
    width: circular ? `${height}px` : width ? '100%' : 'auto',
    flex: flex ? 1 : 'none',
    backgroundColor: disabled || loading ? theme.colors.lightGray : color || theme.colors.pieGreen,
    borderRadius: circular ? `${height / 2}px` : `${radius}px`,
    borderColor: borderColor || 'transparent',
    borderWidth: border ? '2px' : '0',
    borderStyle: 'solid',
    margin: margin || '0',
    color: titleColor || theme.colors.black,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: `${fontSize}px`,
  };

  return (
    <button
      className='button-general'
      style={buttonStyle}
      onClick={onPress}
      disabled={disabled}>
      {loading ? (
        'Loading...'
      ) : (
        <div className='button-content'>
          {icon && <span className='button-icon'>{icon}</span>}
          <span className='button-text'>{title}</span>
        </div>
      )}
    </button>
  );
};
