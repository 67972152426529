import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { createSeller } from '../api/authApi';
// import { setSeller, resetAuthState } from '@features/sellerSlice';
// import { setUser, setApplyToSell } from '@features/userSlice';
import { formatPhoneNumber } from '../helpers/index';
import { db } from '../firebase';
import { PreferencesContext } from '../context/theme';
import { Toast_ } from '../components/toast';
import { InputGeneral } from '../components/inputGeneral';
import { ButtonGeneral } from '../components/buttonGeneral';
import { PieIcons } from '../components/icons/PIE_Icons';
// import { GoBack } from '../components/goBack';
import Select from 'react-select';
import './styles/SellOnPie.css';

const categories = [
  { label: 'Fashion', value: 'Fashion' },
  { label: 'Hair & Beauty', value: 'Hair & Beauty' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'House & Home', value: 'House & Home' },
  { label: 'Pets', value: 'Pets' },
  { label: 'Cars', value: 'Cars' },
  { label: 'Health & Wellness', value: 'Health & Wellness' },
  { label: 'Tech & Electronics', value: 'Tech & Electronics' },
  { label: 'Kids & Babies', value: 'Kids & Babies' },
  { label: 'Other', value: 'Other' },
];

const SellOnPie = () => {
  const { theme } = useContext(PreferencesContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const applyToSell = useSelector((state) => state.user.applyToSell);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    // Equivalent to React Navigation's focus listener
    // You might need to adjust this based on your routing logic
    // dispatch(resetAuthState());
  }, [dispatch]);

  const signUpValidationSchema = yup.object().shape({
    name: yup.string().required('Business Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    category: yup.string().required('Category is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    website: yup
      .string()
      .nullable()
      .test('is-valid-url', 'Invalid website URL', (value) => {
        if (!value) return true;
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}$/i;
        return urlPattern.test(value);
      }),
  });

  const handleSignUp = async (values) => {
    setLoading(true);
    try {
      await signUpValidationSchema.validate(values, { abortEarly: false });

      const sellerAccount = {
        applicationDate: serverTimestamp(),
        sellerName: values.name.trim(),
        sellerEmail: values.email.trim(),
        category: values.category.trim(),
        sellerPhone: values.phoneNumber.trim(),
        sellerWebsite: values.website.trim(),
      };

      const userDocRef = doc(db, 'users', user.user_uid);
      await updateDoc(userDocRef, {
        sellerAccount,
      });

      const seller = await createSeller({
        seller_name: values.name.trim(),
        seller_email: values.email.trim(),
        category: values.category.trim(),
        seller_phone: values.phoneNumber.trim(),
        seller_website: values.website.trim(),
      });

      //   dispatch(setSeller(seller.seller));
      //   dispatch(setUser(seller.user));
      //   dispatch(setApplyToSell(false));
      setLoading(false);

      // Navigate to UserTabs or desired route after successful sign-up
      navigate('/UserTabs');
    } catch (error) {
      let message = 'An unexpected error occurred.';
      if (error.code) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            message = 'The email address is already in use.';
            break;
          case 'auth/invalid-email':
            message = 'The email address is not valid.';
            break;
          case 'auth/operation-not-allowed':
            message = 'Email/password accounts are not enabled.';
            break;
          case 'auth/weak-password':
            message = 'The password is too weak.';
            break;
          default:
            message = error.message || 'An unexpected error occurred.';
        }
      } else if (error.inner && error.inner.length > 0) {
        // Handle Formik validation errors
        message = error.inner.map((err) => err.message).join('\n');
      } else {
        message = error.message || 'An unexpected error occurred.';
      }

      Toast_({
        text1: 'Sign Up Failed',
        text2: message,
        type: 'error',
        position: 'top-right',
        topOffset: 60,
      });

      setLoading(false);
    }
  };

  return (
    <div className='sell-on-pie-container'>
      <div className='scroll-view'>
        <Formik
          initialValues={{
            name: '',
            email: '',
            category: '',
            phoneNumber: '',
            website: '',
          }}
          validationSchema={signUpValidationSchema}
          onSubmit={handleSignUp}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            dirty,
            isValid,
            touched,
          }) => (
            <form
              onSubmit={handleSubmit}
              className='form-container'>
              <div className='title-row'>
                <h1 className='title'>
                  Become a <span className='secondary-word'>Seller</span>
                </h1>
                {applyToSell && (
                  <button
                    className='skip-button'
                    onClick={() => navigate('/UserTabs')}>
                    <span className='skip-text'>Skip</span>
                  </button>
                )}
              </div>
              <InputGeneral
                label={'Business Name*'}
                value={values.name}
                autoCapitalize='words'
                onChange={(e) => handleChange('name')(e.target.value)}
                onBlur={handleBlur('name')}
                errors={dirty && !isValid && touched.name ? errors.name : null}
              />
              <InputGeneral
                label={'Business Email*'}
                value={values.email}
                type='email'
                onChange={(e) => handleChange('email')(e.target.value)}
                onBlur={handleBlur('email')}
                errors={dirty && !isValid && touched.email ? errors.email : null}
                autoCapitalize='none'
              />

              <div className='input-group'>
                <label className='label'>What Category do you sell in the most?</label>
                <Select
                  options={categories}
                  value={categories.find((option) => option.value === values.category)}
                  onChange={(selectedOption) => setFieldValue('category', selectedOption.value)}
                  onBlur={() => handleBlur('category')}
                  placeholder='Select a category'
                  classNamePrefix='react-select'
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderRadius: '25px', // Rounded borders
                      borderColor: '#000',
                      height: '50px',
                      boxShadow: state.isFocused ? '0 0 0 1px #555' : base.boxShadow,
                      '&:hover': {
                        borderColor: '#555', // Darker border on hover
                      },
                    }),
                  }}
                />

                {errors.category && touched.category && (
                  <span className='error-message'>{errors.category}</span>
                )}
              </div>

              <InputGeneral
                label={'Business Phone*'}
                value={formatPhoneNumber(values.phoneNumber)}
                type='tel'
                onChange={(e) => {
                  const rawPhoneNumber = e.target.value.replace(/\D/g, '');
                  handleChange('phoneNumber')(rawPhoneNumber);
                }}
                onBlur={handleBlur('phoneNumber')}
                errors={dirty && !isValid && touched.phoneNumber ? errors.phoneNumber : null}
                maxLength={14}
              />

              <InputGeneral
                label={'Website/Social Media URL'}
                value={values.website}
                type='url'
                onChange={(e) => handleChange('website')(e.target.value)}
                onBlur={handleBlur('website')}
                errors={dirty && !isValid && touched.website ? errors.website : null}
              />

              {/* Buttons */}
              <ButtonGeneral
                title='Become A Seller'
                type='submit'
                loading={loading}
                disabled={!isValid || !dirty}
                width
              />

              {/* Log In */}
              {!user && (
                <div className='login-text-container'>
                  <span className='login-text'>Already have an account?</span>
                  <button
                    type='button'
                    className='login-here-button'
                    onClick={() => navigate('/Login')}>
                    <span className='login-here-text'>Login Here</span>
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SellOnPie;
